import React from 'react'

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import NavLink from 'components/nav-link'
import { useFooterItems } from 'utils/use-footer-items'
import { useSiteMetadata } from 'utils/use-site-metadata'

import './footer.scss'

interface Props {
  author: string
}

const Footer = ({ author }: Props) => {
  const { siteAuthor } = useSiteMetadata()
  const thisAuthor = author ?? siteAuthor
  const navItems = useFooterItems()
  return (
    <Navbar id="footer" className="pb-1" bg="body-dark" variant="dark">
      <div className="container d-flex flex-column justify-content-between align-items-center">
        {/* <div className="container d-flex flex-column flex-lg-row justify-content-between align-items-center"> */}
        {false && (
          <Nav className="d-none d-md-flex">
            {navItems.map((item, index) => (
              <NavLink key={index} href={item.to} className="text-nowrap">
                {item.label}
              </NavLink>
            ))}
          </Nav>
        )}
        <Navbar.Text className="text-nowrap">
          Copyright &copy; {new Date().getFullYear()}
          {thisAuthor && <span className="d-none d-sm-inline">{' ' + thisAuthor}</span>}
        </Navbar.Text>
      </div>
    </Navbar>
  )
}

export default Footer
