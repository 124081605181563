import React from 'react'

import Footer from './footer'
import Header from './header'

interface Props {
  children: React.ReactNode
  location: Location
  bg: string
  variant: string
  className: string
}

const Wrapper = ({ children, location, bg, variant, className }: Props) => {
  const classes = [bg && `bg-${bg}`, variant, className]
  return (
    <div id="wrapper" className={classes.filter(Boolean).join(' ').trim()}>
      <header>
        <a id="home" />
        <Header location={location} />
      </header>
      <main>{children}</main>
      <footer className="mt-auto bg-light">
        <Footer />
      </footer>
    </div>
  )
}

export default Wrapper
