import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import NavLink from 'components/nav-link'
import { useHeaderItems } from 'utils/use-header-items'

import './header.scss'

interface Props {
  location?: Location
}

const Header = ({ location }: Props) => {
  const navItems = useHeaderItems()

  return (
    <>
      {false && (
        <Navbar id="header" fixed="top" bg="body" variant="dark">
          <Nav className="container d-flex flex-column flex-md-row justify-content-around">
            <Navbar.Brand href="/#top">
              <StaticImage
                src="../images/eco-design-logo.png"
                alt="Eco DesignLab logo"
                className="d-inline-block align-top"
                placeholder="none"
                layout="fixed"
                height={36}
                width={36}
              />
            </Navbar.Brand>
            {location.pathname !== '/' && (
              <NavLink href="/" className="d-none d-md-block">
                Home
              </NavLink>
            )}
            {navItems.map((item, index) => (
              <NavLink key={index} href={item.to} className="d-none d-md-block">
                {item.label}
              </NavLink>
            ))}
          </Nav>
        </Navbar>
      )}
    </>
  )
}

export default Header
